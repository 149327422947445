//This is the template for a single blog post

import React from "react"
import { graphql } from "gatsby"
import JobLayout from "../components/jobs/job-layout"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/style.scss"

export default function JobTemplate(data) {
    const pageData = data.data.allMarkdownRemark.edges[0].node
    if(pageData.frontmatter.featuredImage == null){
      return <JobLayout jobTitle={pageData.frontmatter.title} jobDate={pageData.frontmatter.date} pageContent={pageData.html} />
    } else {
      return <JobLayout jobTitle={pageData.frontmatter.title} jobDate={pageData.frontmatter.date} pageContent={pageData.html} 
        featuredImage={pageData.frontmatter.featuredImage.childImageSharp.fluid} />
    }
}

export const query = graphql`query($slug: String!){
  allMarkdownRemark (filter: {fields:{slug: {eq: $slug}}}){
    edges {
      node{
        fields {
          slug
        }
      html
      frontmatter {
        title
        date
        position
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}`