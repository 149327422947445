import React from "react"

export default function Content(props){
    return (
            <div className="content">
                <h1>{props.jobTitle}</h1>
                <i className="card-date">
                {(() => {
                    const dateString = props.jobDate.split("/")
                    const Month = dateString[0]
                    dateString.splice(2,0, Month)
                    dateString.splice(0,1)
                    const dateOut = dateString.join(".")
                    return dateOut
                    })()}
                </i>
                <br />
                <br />
                <div dangerouslySetInnerHTML={{__html: props.pageContent}}></div>
            </div>
        )
}