//
// SINGLE JOB OFFER VIEW
//

import React, { Component } from "react" 

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Header from "../header"
import Sidebar from "../sidebar"
import Content from "./jobs-content"
import Img from "gatsby-image"

import 'leaflet/dist/leaflet.css';

class JobLayout extends Component {

    state = {
        sidebarHide: true,
        sidebarClass: 'sidebar',
    }

    handleToggle = () => {
        console.log(this.state.sidebarHide)
        this.setState((prevState) => {
            if(prevState.sidebarHide){
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar sidebar-show-mobile',
                }
            } else {
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar',
                }
            }
            
        })
    }

    render() {
        
        let featuredImage
    
        if(this.props.featuredImage != null) {
            featuredImage = <Img fluid={this.props.featuredImage} />
        }
        
        return (
            <div className="main" style={{ height: '100%' }}>
                <Header clickFunction={this.handleToggle} menuState={this.state.sidebarHide} /> 
                <div className="wrapper">
                    <Sidebar sidebarClass={this.state.sidebarClass} />
                    <Container fluid className="mainCol">
                        <Row>
                            <Col>
                                <Content jobTitle={this.props.jobTitle} jobDate={this.props.jobDate} pageContent={this.props.pageContent} />
                                {featuredImage}
                            </Col>
                        </Row>
                    </Container>
                    
                </div>
                
            </div>
        )
    }
}

export default JobLayout